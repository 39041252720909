import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";

import { cn } from "./helpers";

const cardVariants = cva("rounded-md border bg-white p-4 ", {
  variants: {
    mobileWide: {
      true: "-mx-4 rounded-none border-x-0 sm:mx-0 sm:rounded-md sm:border-x",
    },
    inverted: {
      true: "dark:bg-gray-50",
    },
  },
  defaultVariants: {
    mobileWide: false,
    inverted: false,
  },
});

export interface CardProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof cardVariants> {}

const Card = React.forwardRef<HTMLDivElement, CardProps>(
  ({ className, mobileWide, inverted, ...props }, ref) => {
    return (
      <div
        className={cn(
          cardVariants({ mobileWide, inverted }),
          "dark:bg-primary-darker dark:text-white",
          className,
        )}
        ref={ref}
        {...props}
      />
    );
  },
);

Card.displayName = "Card";

export { Card, cardVariants };
