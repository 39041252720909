import React, { ChangeEvent, useCallback } from "react";

import { formatPhone } from "@atlas/lib/phone/en_US";

import { TextInput, TextInputProps } from "./TextInput";

export interface PhoneInputProps
  extends Omit<TextInputProps, "type" | "pattern" | "placeholder" | "minLength" | "inputMode"> {
  error?: boolean;
}

export const PhoneInput = React.forwardRef<HTMLInputElement, PhoneInputProps>(
  ({ label = "Phone", onChange, error, ...props }, ref) => {
    const handleChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
          e.target.value = e.target.value ? formatPhone(e.target.value) : "";
          onChange(e);
        }
      },
      [onChange],
    );

    return (
      <TextInput
        ref={ref}
        label={label}
        type="tel"
        pattern="^\([0-9]{3}\) [0-9]{3}-[0-9]{4}$"
        placeholder="(555) 555-5555"
        minLength={10}
        inputMode="tel"
        onChange={handleChange}
        error={error}
        {...props}
      />
    );
  },
);

PhoneInput.displayName = "PhoneInput";
