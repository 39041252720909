import { cva, type VariantProps } from "class-variance-authority";
import { useCallback, useMemo, useState } from "react";

import { dayjs } from "@atlas/lib/date";

import { DateInput, Props as DateInputProps } from "./DateInput";
import { cn } from "./helpers";

interface Props extends DateInputProps {
  minAge: number;
  maxAge?: number;
}

const MAX_AGE = 120;

const birthdayInputVariants = cva("flex flex-col", {
  variants: {
    variant: {
      default: "",
      error: "",
    },
  },
  defaultVariants: {
    variant: "default",
  },
});

export interface BirthdayInputProps extends Props, VariantProps<typeof birthdayInputVariants> {}

export const BirthdayInput = ({
  label,
  minAge,
  maxAge = MAX_AGE,
  onChange,
  value,
  className,
  variant,
  ...dateInputProps
}: BirthdayInputProps) => {
  const [showRangeError, setShowRangeError] = useState(false);

  const handleChange = useCallback(
    (val: dayjs.Dayjs | undefined) => {
      setShowRangeError(false);

      const selectedAge = dayjs.utc().diff(val, "year", true);
      const isValidAge = selectedAge >= (minAge as number) && selectedAge <= (maxAge as number);

      onChange(val);

      if (!isValidAge) {
        return setShowRangeError(true);
      }
    },
    [onChange, maxAge, minAge],
  );

  const minYear = useMemo(() => {
    return dayjs.utc().year() - maxAge;
  }, [maxAge]);

  const maxYear = useMemo(() => {
    return dayjs.utc().year() - minAge;
  }, [minAge]);

  return (
    <div className={cn(birthdayInputVariants({ variant, className }))}>
      <DateInput
        {...dateInputProps}
        label={`${label} (must be ${minAge}+)`}
        minYear={minYear}
        maxYear={maxYear}
        onChange={handleChange}
        value={value}
        data-attr="birthday"
      />
      {showRangeError && (
        <p className="mt-2 text-sm text-red-500">
          Must be between {minAge} and {maxAge} years old.
        </p>
      )}
    </div>
  );
};
