import { cva, type VariantProps } from "class-variance-authority";
import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";
import { useCallback } from "react";

import { monthStringToNumber } from "@atlas/lib/date";

import { cn } from "./helpers";
import { InputLabel } from "./InputLabel";
import { SelectBox } from "./SelectBox";

dayjs.extend(localeData);

const dateInputVariants = cva("flex flex-col gap-2", {
  variants: {
    granularity: {
      day: "",
      month: "",
      year: "",
    },
  },
  defaultVariants: {
    granularity: "day",
  },
});

const wrapperVariants = cva("flex flex-wrap gap-2", {
  variants: {
    granularity: {
      day: "",
      month: "",
      year: "",
    },
  },
  defaultVariants: {
    granularity: "day",
  },
});

export interface Props extends VariantProps<typeof dateInputVariants> {
  // InputLabelProps properties (excluding onClear)
  id?: string;
  label?: string | JSX.Element;
  required?: boolean;
  optionalLabel?: string;
  className?: string;

  // DateInput specific properties
  value?: dayjs.Dayjs;
  onChange: (date?: dayjs.Dayjs) => void;
  clearable?: boolean;
  autoComplete?: "bday";
  minYear?: number;
  maxYear?: number;
  limitToPresent?: boolean;

  // Additional HTML input properties we want to allow
  disabled?: boolean;
  "data-attr"?: string;
}

const MONTHS = dayjs.months().map((m) => ({
  value: m,
  label: m,
}));

const MONTHS_BEFORE_PRESENT = dayjs
  .months()
  .filter((m, i) => dayjs().month() >= i && m)
  .map((m) => ({
    value: m,
    label: m,
  }));

function getYears(min: number, max: number) {
  return Array.from({ length: max - min + 1 }, (_, i) => max - i).map((y) => ({
    value: y.toString(),
    label: y.toString(),
  }));
}

const valueOrDefault = (value?: dayjs.Dayjs, maxYear?: number) => {
  if (value) {
    return value;
  }
  return dayjs
    .utc()
    .date(1)
    .year(maxYear ? maxYear - 1 : dayjs.utc().year());
};

export const DateInput = ({
  label,
  value,
  onChange,
  required,
  optionalLabel,
  clearable,
  autoComplete,
  minYear,
  maxYear,
  disabled,
  granularity = "day",
  "data-attr": dataAttr,
  limitToPresent = false,
  className,
  ...inputProps
}: Props) => {
  const years = getYears(minYear ?? dayjs.utc().year() - 100, maxYear ?? dayjs.utc().year());

  const handleChangeDay = useCallback(
    (day: string) => {
      onChange(valueOrDefault(value, maxYear).date(parseInt(day)));
    },
    [value, maxYear, onChange],
  );

  const handleChangeMonth = useCallback(
    (month: string) => {
      const monthNum = monthStringToNumber(month);
      onChange(valueOrDefault(value, maxYear).month(monthNum));
    },
    [value, maxYear, onChange],
  );

  const handleChangeYear = useCallback(
    (year: string) => {
      onChange(valueOrDefault(value, maxYear).year(parseInt(year)));
    },
    [value, maxYear, onChange],
  );

  const onClear = useCallback(() => {
    onChange(undefined);
  }, [onChange]);

  const [day, month, year] = !value
    ? [undefined, undefined, undefined]
    : [value.date().toString(), (value.month() + 1).toString(), value.year().toString()];

  const monthFullString = value?.format("MMMM");

  const days = Array.from({ length: valueOrDefault(value, maxYear).daysInMonth() }, (_, i) => ({
    value: (i + 1).toString(),
    label: (i + 1).toString(),
  }));

  const hideDay = ["year", "month"].includes(granularity ?? "");
  const hideMonth = ["year"].includes(granularity ?? "");

  return (
    <div className={cn(dateInputVariants({ granularity }), className)} data-attr={dataAttr}>
      <InputLabel
        label={label}
        required={required}
        optionalLabel={optionalLabel}
        onClear={clearable ? onClear : undefined}
      />
      <div className={cn(wrapperVariants({ granularity }))}>
        {!hideMonth && (
          <SelectBox
            className="w-auto min-w-40 flex-1 md:min-w-32"
            options={
              limitToPresent && year === dayjs.utc().year().toString()
                ? MONTHS_BEFORE_PRESENT
                : MONTHS
            }
            value={monthFullString}
            defaultOptionLabel="Month"
            onSelectOption={handleChangeMonth}
            required={required}
            autoComplete={autoComplete === "bday" ? "bday-month" : undefined}
            data-attr={`${dataAttr}-month`}
            disabled={disabled !== undefined ? disabled : false}
          />
        )}
        {!hideDay && (
          <SelectBox
            className="w-auto max-w-24 flex-1 md:max-w-none"
            options={days ?? []}
            value={day}
            defaultOptionLabel="Day"
            onSelectOption={handleChangeDay}
            required={required}
            autoComplete={autoComplete === "bday" ? "bday-day" : undefined}
            disabled={!days || (disabled !== undefined ? disabled : false)}
            data-attr={`${dataAttr}-day`}
          />
        )}
        <SelectBox
          className="w-auto min-w-[6.5rem] flex-1"
          options={years}
          value={year}
          defaultOptionLabel="Year"
          onSelectOption={handleChangeYear}
          required={required}
          disabled={disabled !== undefined ? disabled : false}
          autoComplete={autoComplete === "bday" ? "bday-year" : undefined}
          data-attr={`${dataAttr}-year`}
        />
        <input {...inputProps} type="hidden" defaultValue={`${year}-${month}-${day}`} />
      </div>
    </div>
  );
};
