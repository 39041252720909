import { cva, type VariantProps } from "class-variance-authority";
import { forwardRef, useCallback, ReactNode, useState } from "react";

import { cn } from "./helpers";
import { InputLabel, InputLabelProps } from "./InputLabel";

export interface Option<ValueType = string> {
  isCustom?: boolean;
  value: ValueType;
  label: string;
  disabled?: boolean;
}

const selectBoxVariants = cva(
  "cursor-pointer appearance-none rounded-md border bg-gray-100 bg-[length:1.5em_1.5em] bg-[right_0.5rem_center] bg-no-repeat focus:border-primary focus:outline-none dark:bg-primary-dark dark:border-none dark:text-white",
  {
    variants: {
      variant: {
        default: "text-5 h-10 p-2",
        sm: "text-4 h-9 px-3 py-1.5",
        lg: "text-6 h-11 px-5 py-2.5",
      },
      empty: { true: "text-gray-500" },
    },
    defaultVariants: {
      variant: "default",
      empty: false,
    },
  },
);

export interface Props<T>
  extends Omit<React.HTMLProps<HTMLSelectElement>, "value" | "onChange" | "label">,
    Pick<InputLabelProps, "optionalLabel"> {
  label?: ReactNode;
  defaultOptionLabel?: string;
  options: Option<T>[];
  onSelectOption?: (option: T | null) => void;
  clearable?: boolean;
  value?: T | null;
  variant?: VariantProps<typeof selectBoxVariants>["variant"];
}

const SelectBox = forwardRef<HTMLSelectElement, Props<any>>(
  (
    {
      label,
      options,
      required,
      optionalLabel,
      onSelectOption,
      value,
      defaultOptionLabel = "Please select",
      className,
      clearable,
      variant,
      defaultValue,
      ...props
    },
    ref,
  ) => {
    const [internalValue, setInternalValue] = useState<any>(defaultValue ?? null);
    const isControlled = value !== undefined;
    const currentValue = isControlled ? value : internalValue;

    const handleOnChange = useCallback(
      (e: React.ChangeEvent<HTMLSelectElement>) => {
        let newValue: any = e.target.value;
        if (newValue === "true" || newValue === "false") {
          newValue = newValue === "true";
        }

        if (!isControlled) {
          setInternalValue(newValue);
        }

        onSelectOption?.(newValue);
      },
      [onSelectOption, isControlled],
    );

    const clearSelection = useCallback(() => {
      if (!isControlled) {
        setInternalValue(null);
      }
      onSelectOption?.(null);
    }, [onSelectOption, isControlled]);

    return (
      <div className={cn("flex min-w-0 flex-col gap-2", className)}>
        {typeof label === "string" ? (
          <InputLabel
            label={label}
            required={required}
            optionalLabel={optionalLabel}
            onClear={clearable ? clearSelection : undefined}
          />
        ) : (
          label
        )}
        <select
          className={cn(
            selectBoxVariants({ variant, empty: !currentValue }),
            "bg-[url('data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIGZpbGw9J25vbmUnIHZpZXdCb3g9JzAgMCAyMCAyMCc+PHBhdGggc3Ryb2tlPScjNmI3MjgwJyBzdHJva2UtbGluZWNhcD0ncm91bmQnIHN0cm9rZS1saW5lam9pbj0ncm91bmQnIHN0cm9rZS13aWR0aD0nMS41JyBkPSdtNiA4IDQgNCA0LTQnLz48L3N2Zz4=')]",
            "hover:opacity-70",
          )}
          value={currentValue ? (currentValue as string) : ""}
          onChange={handleOnChange}
          required={required}
          ref={ref}
          {...props}
        >
          {!currentValue ? (
            <option value="" disabled>
              {defaultOptionLabel}
            </option>
          ) : undefined}
          {options &&
            options?.map(({ value, label, disabled }, index) => (
              <option
                key={index}
                value={(value as string) ?? ""}
                disabled={disabled}
                className="text-black"
              >
                {label}
              </option>
            ))}
        </select>
      </div>
    );
  },
);

SelectBox.displayName = "SelectBox";

export { SelectBox };
