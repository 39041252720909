import { allForYear as getYearHolidays } from "@18f/us-federal-holidays";
import dayjs, { type Dayjs } from "dayjs";
import localeData from "dayjs/plugin/localeData";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import dayjsBusinessTime from "dayjs-business-time";
import { z } from "zod";

dayjs.extend(utc);
dayjs.extend(localeData);
dayjs.extend(dayjsBusinessTime);
dayjs.extend(timezone);

export { dayjs, type Dayjs };

export const DayjsSchema = z.custom<Dayjs>((value) => value instanceof dayjs, "Invalid date");

export const complianceBusinessDays = 11;

export const formatDay = (text: string) =>
  text
    ? new Date(text).toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      })
    : "";

export const monthStringToNumber = (month: string) => dayjs.months().indexOf(month);

export const formatTime = (text: string, timezone?: string) => {
  if (!text) return "";
  if (timezone) {
    return dayjs.utc(new Date(text)).tz(timezone).format("ddd, MMM D, YYYY h:mm A UTCZZ");
  } else {
    return dayjs.utc(new Date(text)).format("ddd, MMM D, YYYY h:mm A UTC");
  }
};

export const adaptiveDate = (date: Date | null) => {
  if (!date) return "";
  const now = new Date();
  if (!date.toISOString) date = new Date(date);
  if (date.toISOString().slice(0, 10) === now.toISOString().slice(0, 10))
    return date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
    });

  if (date.getFullYear() === now.getFullYear())
    return date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
    });

  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
};

const complianceDateCache = new Map<string, dayjs.Dayjs>();
export const getCachedComplianceDate = (date: Date) => {
  if (!date) return;
  const dateKey = date.toISOString();

  if (complianceDateCache.has(dateKey)) {
    return complianceDateCache.get(dateKey);
  }

  const convertedDate = dayjs.utc(date);

  const holidays = getYearHolidays(convertedDate.year())
    .concat(getYearHolidays(convertedDate.year() - 1))
    .map((holiday) => holiday.dateString);

  dayjs.setHolidays(holidays);

  const complianceDate = convertedDate.addBusinessDays(complianceBusinessDays);
  complianceDateCache.set(dateKey, complianceDate);

  return complianceDate;
};

export const getComplianceDate = (date: Date) => {
  if (!date) return;
  const convertedDate = dayjs.utc(date);

  const holidays = getYearHolidays(convertedDate.year())
    .concat(getYearHolidays(convertedDate.year() - 1))
    .map((holiday) => holiday.dateString);

  dayjs.setHolidays(holidays);

  return convertedDate.addBusinessDays(complianceBusinessDays);
};

export const getComplianceCutOff = (overrideCurrentDate?: Date) => {
  const now = overrideCurrentDate ? dayjs.utc(overrideCurrentDate) : dayjs.utc();

  const holidays = getYearHolidays(now.year())
    .concat(getYearHolidays(now.year() - 1))
    .map((holiday) => holiday.dateString);

  dayjs.setHolidays(holidays);

  // since we're returning a cutoff,
  // if the current day is not a business day, then an additional day needs
  // to be excluded as the substraction starts from the next business day.
  return now.subtractBusinessDays(complianceBusinessDays + (now.isBusinessDay() ? 0 : 1)).toDate();
};
