import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";

import { cn } from "./helpers";

const inputLabelVariants = cva("text-left", {
  variants: {
    variant: {
      default: "",
      required: "",
      optional: "",
      clearable: "",
    },
  },
  defaultVariants: {
    variant: "default",
  },
});

export interface InputLabelProps
  extends React.LabelHTMLAttributes<HTMLLabelElement>,
    VariantProps<typeof inputLabelVariants> {
  label?: string | JSX.Element;
  required?: boolean;
  optionalLabel?: string;
  onClear?: () => void;
}

const InputLabel = React.forwardRef<HTMLLabelElement, InputLabelProps>(
  ({ className, variant, label, required, optionalLabel, onClear, ...props }, ref) => {
    const optLabel = optionalLabel ?? "optional";

    if (!label) {
      return null;
    }

    return (
      <label className={cn(inputLabelVariants({ variant, className }))} ref={ref} {...props}>
        {label}
        {required ? (
          <span className="text-red"> *</span>
        ) : (
          <span className="text-sm text-gray-600"> ({optLabel})</span>
        )}
        {onClear && (
          <a className="float-right cursor-pointer" onClick={onClear}>
            Clear
          </a>
        )}
      </label>
    );
  },
);

InputLabel.displayName = "InputLabel";

export { InputLabel, inputLabelVariants };
