import { cva } from "class-variance-authority";
import { InputHTMLAttributes, forwardRef, ElementType } from "react";

import { cn } from "./helpers";
import { Icon } from "./Icon";
import { InputLabel, InputLabelProps } from "./InputLabel";

const textInputVariants = cva(
  "w-full text-black appearance-none rounded-md transition-colors placeholder:text-gray-500 focus:outline-none focus:ring-1 disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-500",
  {
    variants: {
      hasLeftIcon: {
        true: "pl-10",
        false: "pl-3",
      },
      variant: {
        default:
          "h-10 border border-colors-border-neutrals-primary bg-white px-3 py-2 shadow-sm focus:border-primary focus:ring-primary focus:ring-offset-2 dark:bg-primary-dark dark:border-none dark:placeholder-slate-400 dark:text-white",
        textarea: "min-h-56 shadow-sm focus:ring-offset-2",
        filled:
          "border-b border-gray-300 bg-gray-100 focus:border-primary focus:bg-white focus:ring-primary focus:ring-offset-0",
        ghost: "h-10 bg-transparent px-3 py-2 focus:border-transparent focus:ring-0",
      },
      error: {
        true: "border-red-500 focus:border-red-500 focus:ring-red-500",
        false: "",
      },
    },
    compoundVariants: [
      {
        hasLeftIcon: true,
        variant: ["default", "filled", "ghost"],
        className: "pl-10",
      },
    ],
    defaultVariants: {
      hasLeftIcon: false,
      variant: "default",
      error: false,
    },
  },
);

export interface TextInputProps
  extends InputHTMLAttributes<HTMLInputElement>,
    Pick<InputLabelProps, "optionalLabel"> {
  label?: string | JSX.Element;
  placeholder?: string;
  autocomplete?: boolean;
  required?: boolean;
  defaultValue?: string;
  leftIcon?: string;
  inputClassName?: string;
  as?: ElementType;
  variant?: "default" | "filled" | "textarea" | "ghost";
  error?: boolean;
}

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      label,
      value,
      defaultValue,
      required,
      optionalLabel,
      className,
      leftIcon,
      inputClassName,
      as: Component = "input",
      variant = "default",
      error = false,
      placeholder,
      ...args
    },
    ref,
  ) => {
    // Only pass value prop if it's defined, otherwise component remains uncontrolled
    const inputProps = value !== undefined ? { value } : {};

    const renderLabel = () => {
      if (variant === "filled" && typeof label === "string") {
        return null;
      }
      if (typeof label === "string") {
        return <InputLabel label={label} required={required} optionalLabel={optionalLabel} />;
      }
      return label;
    };

    const inputPlaceholder =
      variant === "filled" && typeof label === "string" ? label : placeholder;

    return (
      <div className={cn("flex min-w-0 flex-col gap-2", className)}>
        {renderLabel()}
        <div className="relative flex items-center">
          {leftIcon && (
            <Icon icon={leftIcon} className="pointer-events-none absolute left-3 text-gray-500" />
          )}
          <Component
            ref={ref}
            type="text"
            required={required}
            defaultValue={defaultValue}
            placeholder={inputPlaceholder}
            className={cn(
              textInputVariants({ hasLeftIcon: !!leftIcon, variant, error }),
              "placeholder:text-gray-500",
              "appearance-none",
              variant === "default" || variant === "textarea"
                ? "focus:ring-offset-2"
                : "focus:ring-offset-0",
              inputClassName,
            )}
            {...inputProps}
            {...args}
          />
        </div>
      </div>
    );
  },
);

TextInput.displayName = "TextInput";
